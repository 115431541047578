import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getEvents();
  }, [])

  async function getEvents() {
    const response = await fetch('https://api.github.com/events');
    const events = await response.json();
    setEvents(events);
  }

  return (
    <div className="App">
      <h1>Karl Coelho</h1>
      <p>Software Engineer.</p>

      <div className="links">
        <a href="https://github.com/karcoelho">Github</a> | &nbsp;
        <a href="https://linkedin.com/in/karcoelho">LinkedIn</a> | &nbsp;
        <a href="mailto:karl@gmail.com">Email</a>
      </div>
      
      <br/><br/>
      <div className="events">
        {events.map(event => <Event {...event}/> )}
      </div>
    </div>
  );
}

const Event = ({type, actor}) => (
  <div className="event">
    <h1>{type}</h1>
    <p>{actor.avatar_url ? (
      <a href={"https://github.com/"+actor.display_login} target="_blank" rel="noopener noreferrer">
        <img src={actor.avatar_url} className="author-image" alt=""/>
      </a>
    ) : (
      <span>lol</span>
    )}</p>
    <p>by {actor.display_login}</p>
  </div>
)

export default App;
